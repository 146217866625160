import {
  Mappedin,
  MapView,
  showVenue,
  TShowVenueOptions,
} from "@mappedin/mappedin-js";
import { useEffect, useState } from "react";

/**
 * Declarative API to render the MapView using showVenue
 */
export default function useMapView(
  el: HTMLElement | null,
  venue: Mappedin | undefined,
  options?: TShowVenueOptions
) {
  // Store the MapView instance in a state variable
  const [mapView, setMapView] = useState<MapView | undefined>();

  // Render the MapView asynchronously
  useEffect(() => {
    const renderVenue = async () => {
      // Do nothing if the  map container or venue data are not initialized
      if (el == null || venue == null) {
        return;
      }

      // Do nothing if the mapView is already rendered with the current venue data
      if (mapView != null && mapView.venue.venue.id === venue.venue.id) {
        return;
      }

      // If the mapView has been rendered with old data, destroy it
      if (mapView != null) {
        mapView.destroy();
      }

      // Try to render the mapView
      try {
        const _mapView = await showVenue(el, venue, options);
        setMapView(_mapView);
      } catch (e) {
        // Handle error
        console.log(e);
        setMapView(undefined);
      }
    };
    renderVenue();
  }, [el, venue, options, mapView]);

  // Initialize the MapView after it's been rendered
  useEffect(() => {
    if (mapView == null) {
      return;
    }

    // Make the MapView interactive
    mapView.addInteractivePolygonsForAllLocations();
    mapView.labelAllLocations();

    return () => {
      mapView.removeAllInteractivePolygons();
      mapView.FlatLabels.removeAll();
      mapView.FloatingLabels.removeAll();
    };
  }, [mapView]);

  // Return the MapView instance
  return mapView;
}
