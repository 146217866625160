import "./styles.css";
import "@mappedin/mappedin-js/lib/mappedin.css";
import { useEffect, useMemo, useRef, useState } from "react";
import useVenue from "./hooks/useVenue";
import useMapView from "./hooks/useMapView";
import useOfflineSearch from "./hooks/useOfflineSearch";
import useSelectedLocation from "./hooks/useSelectedLocation";
import {
  MappedinDirections,
  MappedinLocation,
  MappedinMap,
  MappedinMapGroup,
  TGetVenueOptions,
  TMappedinDirective,
} from "@mappedin/mappedin-js";

// See Trial API key Terms and Conditions
// https://developer.mappedin.com/guides/api-keys
const options: TGetVenueOptions = {
  venue: "mappedin-demo-mall",
  clientId: "5eab30aa91b055001a68e996",
  clientSecret: "RJyRXKcryCMy4erZqqCbuB1NbR66QTGNXVE0x3Pg6oCIlUR1",
};

export default function App() {
  const venue = useVenue(options);
  const mapGroups = venue?.mapGroups;
  const mapEl = useRef<HTMLDivElement | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [map, setMap] = useState<MappedinMapGroup | any>([]);
  const [level, setLevel] = useState<MappedinMap | any>([]);
  const [directions, setDirections] = useState<TMappedinDirective[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const mapView = useMapView(mapEl.current, venue);
  const { selectedLocation, setSelectedLocation } =
    useSelectedLocation(mapView);
  const results = useOfflineSearch(venue, searchQuery);

  // Memoize search results because useOfflineSearch returns a deferred value
  const searchResults = useMemo(
    () =>
      results
        .filter((result) => result.type === "MappedinLocation")
        .map((result) => (
          <div
            id="search-result"
            key={(result.object as MappedinLocation).name}
            onClick={() => {
              setSelectedLocation(result.object as MappedinLocation);
              setSearchQuery("");
            }}
          >
            {`${result.object.name}`}
          </div>
        )),
    [results, setSelectedLocation]
  );

  const onMapChange = (id: string) => {
    const selectedMap = mapGroups?.find((x) => x.id === id) || [];
    console.log({ selectedMap, mapGroups, id });
    setMap(selectedMap);
  };

  const onLevelChange = async (id: string) => {
    const selectedLevel =
      map?.maps?.find((x: MappedinMap) => x.id === id) || [];
    setLevel(selectedLevel);
    await mapView?.setMap(selectedLevel);
  };

  const setNaviation = (name: string) => {
    if (venue) {
      const startLocation = venue.locations.find(
        (location) => location.name === "Target"
      );
      const endLocation = venue.locations.find(
        (location) => location.name === name
      );
      if (startLocation && endLocation) {
        const direction = startLocation.directionsTo(endLocation, {
          accessible: true,
        });
        mapView?.Journey.draw(direction, {
          pathOptions: {
            color: "green",
          },
        });
        setDirections(direction.instructions);
      }
    }
  };

  useEffect(() => {
    if (mapGroups) {
      setMap(mapGroups[0]);
      setLevel(mapGroups[0].maps[0]);
    }
  }, [mapGroups, venue, mapView]);

  useEffect(() => {
    if (mapView) {
      mapView.Camera.set({
        rotation: 1,
        tilt: 1,
      });
      // mapView.Camera.interactions.set({ rotationAndTilt: false });
    }
  }, [venue, mapView]);

  useEffect(() => {
    console.log(selectedLocation?.polygons, "selectedLocation");
  }, [selectedLocation]);

  return (
    <>
      {!venue ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div id="app">
            <div className="ui-container">
              <div id="ui">
                <div>{`Selected: ${selectedLocation?.name ?? "None"}`}</div>
                {selectedLocation?.name && (
                  <>
                    <button
                      type="button"
                      style={{
                        marginTop: "5%",
                      }}
                      onClick={() => setNaviation(selectedLocation?.name)}
                    >
                      Get Directions
                    </button>
                  </>
                )}
                <input
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  placeholder="Search..."
                />
                <div id="results">{searchResults}</div>
              </div>

              {directions && directions.length > 0 && (
                <div id="directions">
                  <h3>{`Directions: Step ${currentStep + 1} / ${
                    directions.length
                  }`}</h3>
                  {directions[currentStep] && (
                    <div className="distance">
                      {Math.round(directions[currentStep].distance)} meters :{" "}
                      {directions[currentStep].instruction}
                    </div>
                  )}
                  <div className="direction-btn">
                    <button
                      type="button"
                      className="step-btn"
                      disabled={currentStep === 0}
                      onClick={() => setCurrentStep(currentStep - 1)}
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      className="step-btn"
                      disabled={currentStep === directions.length - 1}
                      onClick={() => setCurrentStep(currentStep + 1)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}

              <div id="selectorDiv">
                {mapGroups?.length && mapGroups.length > 1 && (
                  <select onChange={(e) => onMapChange(e.target.value)}>
                    {mapGroups?.map((e) => (
                      <option value={e.id}>{e.name}</option>
                    ))}
                  </select>
                )}
                {map && map.maps && map.maps.length > 0 && (
                  <select onChange={(e) => onLevelChange(e.target.value)}>
                    {map?.maps?.map((e: MappedinMap) => (
                      <option value={e.id}>{e.name}</option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            <div id="map-container" ref={mapEl}></div>
          </div>
        </>
      )}
    </>
  );
}
